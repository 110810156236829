var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_c('a-modal',{attrs:{"confirmLoading":_vm.loading,"title":"Email Address","visible":_vm.visible,"okText":"Verify"},on:{"cancel":function($event){return _vm.$emit('close')},"ok":_vm.onConfirm}},[_c('p',[_vm._v("A verification link will be sent to the "),_c('strong',[_vm._v("new")]),_vm._v(" email address you enter below. Changes do not take effect until you click the link.")]),_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onConfirm.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"New Email Address","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Email address is required.' }],
            }, {
              trigger: 'blur',
              rules: [{ type: 'email', message: 'Email address is invalid.' }],
            }]
          }]),expression:"['email', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Email address is required.' }],\n            }, {\n              trigger: 'blur',\n              rules: [{ type: 'email', message: 'Email address is invalid.' }],\n            }]\n          }]"}],attrs:{"name":"email","placeholder":"name@company.com","type":"email"}})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }