var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NotificationEmail',{staticClass:"mb-3"}),_c('div',{staticClass:"card mb-3"},[_vm._m(0),_c('div',{staticClass:"card-body border-bottom"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 col-lg-9"},[_c('div',{staticClass:"mb-2"},[_c('a-checkbox',{model:{value:(_vm.values.threshold_supplies),callback:function ($$v) {_vm.$set(_vm.values, "threshold_supplies", $$v)},expression:"values.threshold_supplies"}},[_vm._v(" Supplies ")])],1),_vm._m(2),_c('div',{staticClass:"mb-2"},[_c('a-checkbox',{model:{value:(_vm.values.threshold_validations),callback:function ($$v) {_vm.$set(_vm.values, "threshold_validations", $$v)},expression:"values.threshold_validations"}},[_vm._v(" Validations ")])],1),_vm._m(3)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-12 col-lg-9"},[_c('div',{staticClass:"mb-2"},[_c('a-checkbox',{model:{value:(_vm.values.announcement),callback:function ($$v) {_vm.$set(_vm.values, "announcement", $$v)},expression:"values.announcement"}},[_vm._v(" Announcements ")])],1),_vm._m(5)])])]),_c('div',{staticClass:"card-footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Save ")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Notifications")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('label',{staticClass:"label"},[_vm._v("Threshold Warnings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"text-muted"},[_c('p',[_vm._v("Send a notification if 75% of the month’s supplies are crossed.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"text-muted"},[_c('p',[_vm._v("Send a notification if 75% of the month’s validations are crossed.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('label',{staticClass:"label"},[_vm._v("Others")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"text-muted"},[_c('p',[_vm._v("Service-related information that may be relevant for your account.")])])
}]

export { render, staticRenderFns }