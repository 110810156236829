<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      title="Email Address"
      :visible="visible"
      okText="Verify"
      @cancel="$emit('close')"
      @ok="onConfirm"
    >
      <p>A verification link will be sent to the <strong>new</strong> email address you enter below. Changes do not take effect until you click the link.</p>
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="New Email Address" :colon="false" required>
          <a-input
            name="email"
            placeholder="name@company.com"
            type="email"
            v-decorator="['email', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Email address is required.' }],
              }, {
                trigger: 'blur',
                rules: [{ type: 'email', message: 'Email address is invalid.' }],
              }]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$httpd.put(`/user`, values)

          this.$message.success('Email has been sent out to confirm your email change request.')
          this.$store.commit('user', data)
          this.$emit('success')
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
