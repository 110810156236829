<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-6">
          <strong>Email Address</strong>
        </div>
        <div class="col-6 text-right">
          <NotificationEmailChange
            :visible="visible"
            @close="visible = false"
            @success="visible = false"
          >
            <a-button type="primary" ghost size="small" @click="visible = true">
              <i class="fal fa-pen mr-2"></i> Change
            </a-button>
          </NotificationEmailChange>
        </div>
      </div>
    </div>
    <div v-if="isPending">
      <div class="card-body">
        <a-tooltip title="Unverified" placement="left">
          <i class="fad fa-exclamation-circle text-danger mr-1"></i> {{ email }}
        </a-tooltip>
        <a-button v-if="verified" type="link" size="small" @click="onAbort">
          Abort Change
        </a-button>
      </div>
    </div>
    <div v-else>
      <div class="card-body">
        <a-tooltip v-if="verified" title="Verified" placement="left">
          <i class="fad fa-check-circle text-success mr-1"></i> {{ email }}
        </a-tooltip>
        <a-tooltip v-else title="Unverified" placement="left">
          <i class="fad fa-exclamation-circle text-danger mr-1"></i> {{ email }}
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationEmailChange from '@/components/Notification/NotificationEmailChange'

export default {
  components: {
    NotificationEmailChange,
  },

  data() {
    return {
      visible: false,
      verified: this.$store.state.user.verified,
    }
  },

  methods: {
    async onAbort() {
      try {
        var { data } = await this.$httpd.delete(`/user/email`)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      this.$store.commit('user', data)
      this.$message.success('Email change request has been canceled.')
    },
  },

  computed: {
    isPending() {
      return !!this.$store.state.user.email_pending
    },
    email() {
      return this.isPending ? this.$store.state.user.email_pending : this.$store.state.user.email
    }
  },
}
</script>
