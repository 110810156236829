<template>
  <div>
    <NotificationEmail
      class="mb-3"
    />
    <div class="card mb-3">
      <div class="card-header">
        <strong>Notifications</strong>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Threshold Warnings</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-checkbox v-model="values.threshold_supplies">
                Supplies
              </a-checkbox>
            </div>
            <small class="text-muted">
              <p>Send a notification if 75% of the month’s supplies are crossed.</p>
            </small>
            <div class="mb-2">
              <a-checkbox v-model="values.threshold_validations">
                Validations
              </a-checkbox>
            </div>
            <small class="text-muted">
              <p>Send a notification if 75% of the month’s validations are crossed.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Others</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-checkbox v-model="values.announcement">
                Announcements
              </a-checkbox>
            </div>
            <small class="text-muted">
              <p>Service-related information that may be relevant for your account.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading" @click="onSubmit">
          Save
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationEmail from '@/components/Notification/NotificationEmail'

export default {
  components: {
    NotificationEmail,
  },

  data() {
    return {
      loading: false,
      values: {
        announcement: this.$store.state.user.notifications.announcement,
        threshold_supplies: this.$store.state.user.notifications.threshold_supplies,
        threshold_validations: this.$store.state.user.notifications.threshold_validations,
      },
    }
  },

  methods: {
    async onSubmit() {
      this.loading = true

      try {
        var { data } = await this.$httpd.put(`/user`, this.values)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$store.commit('user', data)
      this.$message.success('Account has been updated.')
    },
  },
}
</script>
